const DAY_MS = 60 * 60 * 24 * 1000;
export const LOCALSTORAGE_KEY = 'wix.promote.appDownloadBanner.dismissed';
export const DISMISS_EXPIRY_MS = DAY_MS * 7;
export const GET_APP_EXPIRY_MS = DAY_MS * 28;
export const SUPPORTED_USER_AGENTS = /(android|iphone)(?!.*(googlebot|tablet))/i;
export const SCRIPT_TAG_ID = 'wix-mobile-app-banner';
export const BI_EVENTS_URL = 'https://web.archive.org/web/20200804023008/https://frog.wix.com/oneapp';
export const BI_EVENT_SRC = 67;
export const BI_EVENT_BANNER_SHOWN = 622;
export const BI_EVENT_BANNER_CLICKED = 623;
export const WIX_SITE_CONTAINER_ID = 'SITE_CONTAINER';
export const MOVE_VIEWPORT = false; // this breaks "back-to-top" in-page links
export const BANNER_RENDER_DELAY = 500;
export const HIDE_AFTER = 18000;
