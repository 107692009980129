const REF_VALUE = 'pre_banner';

export function getLinkWithRef(link) {
  if(!link) {
    return undefined;
  }
  const queryParamsObject = extractQueryParams(link);
  const paramsObject = queryParamsObject.params;
  const cleanLink = queryParamsObject.cleanLink;

  if (!paramsObject) {
    return `${link}?ref=${REF_VALUE}`;
  }
  const currentRef = paramsObject.ref;

  if (!currentRef) {
    return `${link}&ref=${REF_VALUE}`;
  }
  if (currentRef !== REF_VALUE) {
    paramsObject.ref = `${currentRef},${REF_VALUE}`;
  }
  const newQueryParams = convertObjectToQueryParams(paramsObject);
  return `${cleanLink}?${newQueryParams}`;
}

function extractQueryParams(link) {
  const [cleanLink, queryParams] = link.split('?')

  if (!queryParams) {
    return {
      cleanLink,
      params: undefined
    };
  }
  const paramsArray = queryParams.split('&');
  const parsed = {};

  paramsArray.forEach(param => {
    const pair = param.split('=');
    parsed[pair[0]] = pair[1];
  });

  return {
    cleanLink,
    params: parsed
  };
}

function convertObjectToQueryParams(objectToConvert) {
  return Object
    .keys(objectToConvert)
    .map((key) => objectToConvert[key] === undefined ? null : `${key}=${objectToConvert[key]}`)
    .filter((item) => item !== null)
    .join('&');
}
