import locale from './locale.json';

const DEFAULT_KEY = 'en';

export function get() {
  const browserLanguage = navigator.language;

  if(!browserLanguage) {
    return locale[DEFAULT_KEY];
  }
  if(browserLanguage < 2) {
    return locale[DEFAULT_KEY];
  }
  const languageKey = browserLanguage.substring(0, 2).toLowerCase();
  const terms = locale[languageKey];

  if (terms) {
    return terms;
  }
  return locale[DEFAULT_KEY];
}
