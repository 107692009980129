import styles from './banner.scss';
import BiEventsLogger from './bi-events-logger';
import closeBtnSvg from './assets/close-btn.inline.svg';
import {
  LOCALSTORAGE_KEY,
  GET_APP_EXPIRY_MS,
  DISMISS_EXPIRY_MS,
  SCRIPT_TAG_ID,
  WIX_SITE_CONTAINER_ID,
  MOVE_VIEWPORT,
  BANNER_RENDER_DELAY,
  HIDE_AFTER,
} from './config';
import * as helpers from './helpers';
import * as termsProvider from './terms-provider';
import {getLinkWithRef} from "./link-editor";

class BannerComponent {
  bannerElement;

  constructor() {
    this.biLogger = new BiEventsLogger();
    this.bannerHeight = parseInt(styles.bannerHeight, 10);
    this.terms = termsProvider.get();
  }

  shouldShowBanner() {
    return !this.isBannerDismissed() && helpers.isMobile(navigator.userAgent);
  }

  onCloseClick(event) {
    event.stopPropagation();
    this.biLogger.logBannerDismissed();
    this.dismissBanner(DISMISS_EXPIRY_MS);
  }

  onBannerClick() {
    this.biLogger.logBannerClicked();
    this.dismissBanner(GET_APP_EXPIRY_MS);
    window.open(this.getAppLink(), '_blank');
  }

  isBannerDismissed() {
    return helpers.localStorageRead(LOCALSTORAGE_KEY);
  }

  dismissBanner(expiration) {
    helpers.localStorageWrite(LOCALSTORAGE_KEY, true, expiration);
    clearTimeout(this.autoHideTimer);
    return this.hideBanner();
  }

  hideBanner() {
    const hideAnimationDuration = parseInt(styles.animationDuration, 10);
    this.bannerElement.classList.add(styles.bannerRemove);
    if (MOVE_VIEWPORT) {
      this.restoreSiteViewport();
    }
    return new Promise(resolve => {
      setTimeout(() => {
        // cleanup after banner was already hidden and animation ended
        this.cleanup();
        resolve();
      }, hideAnimationDuration + 20);
    });
  }

  cleanup() {
    helpers.removeElement(this.bannerElement);
    this.siteContainer.classList.remove(styles.siteContainerPushAnimation);
  }

  getAppLink() {
    const selfScript = document.getElementById(SCRIPT_TAG_ID);
    if (!selfScript) {
      console.error(
        'Unable to find app banner script.' +
          'please make sure you included the script along with a %s id',
        SCRIPT_TAG_ID,
      );
      return '#';
    }
    const appLink = selfScript.getAttribute('data-app-link');
    return getLinkWithRef(appLink);
  }

  renderBanner() {
    const template = `
    <div class="${styles.banner}" data-hook="wix-app-banner">
      <button class="${styles.closeButton}" data-hook="wix-app-banner-close">
        ${closeBtnSvg}
      </button>
      <div class="${styles.text}">
        <strong>${this.terms.title}</strong>
        <br />
        ${this.terms.subtitle}
      </div>
      <span
        data-hook="wix-app-banner-link"
        class="${styles.appLink}">
        ${this.terms.getAppText}
      </span>
    </div>`;
    this.bannerElement = helpers.createElementFromString(template);
    this.siteContainer = document.getElementById(WIX_SITE_CONTAINER_ID);
    this.innerContainer = this.siteContainer.firstElementChild;
    this.bindEvents();
    if (MOVE_VIEWPORT) {
      this.moveSiteViewport();
    }
    helpers.prependElementToBody(this.bannerElement);
    this.biLogger.logBannerShown();
    this.autoHideTimer = setTimeout(() => {
      this.hideBanner();
    }, HIDE_AFTER);
  }

  moveSiteViewport() {
    this.siteContainer.classList.add(styles.siteContainerPush);
    this.siteContainer.classList.add(styles.siteContainerPushAnimation);
    this.setContainerHeight();
    this.registerSizingEvents();
  }

  registerSizingEvents() {
    const onResize = this.setContainerHeight.bind(this);
    window.addEventListener('onorientationchange', onResize, false);
    window.addEventListener('resize', onResize, false);
  }

  /**
   * This will override the "view height" used by css, as VH does not
   * take the address bar into account.
   */
  setContainerHeight() {
    const newHeight = window.innerHeight - this.bannerHeight;
    this.innerContainer.style.setProperty('height', `${newHeight}px`);
  }

  restoreSiteViewport() {
    this.siteContainer.classList.remove(styles.siteContainerPush);
  }

  bindEvents() {
    const closeBtn = this.bannerElement.querySelector(`.${styles.closeButton}`);
    closeBtn.addEventListener('click', this.onCloseClick.bind(this), false);
    this.bannerElement.addEventListener('click', this.onBannerClick.bind(this), false);
  }

  onLoad() {
    if (!this.shouldShowBanner()) {
      return;
    }
    document.addEventListener('DOMContentLoaded', () => {
      setTimeout(this.renderBanner.bind(this), BANNER_RENDER_DELAY);
    });
  }
}

export default BannerComponent;
