import {
  BI_EVENTS_URL,
  BI_EVENT_BANNER_SHOWN,
  BI_EVENT_BANNER_CLICKED,
  BI_EVENT_SRC,
} from './config';
import { performGetRequest } from './helpers';

/**
 * Light BI events logger.
 * Wix bi-logger was intentionally not used, to avoid having to load heavy libs.
 */
class BiEventsLogger {
  logBannerShown() {
    return this.log({ evid: BI_EVENT_BANNER_SHOWN });
  }

  logBannerClicked() {
    return this.log({ evid: BI_EVENT_BANNER_CLICKED, type: 'click' });
  }

  logBannerDismissed() {
    return this.log({ evid: BI_EVENT_BANNER_CLICKED, type: 'dismiss' });
  }

  log(params) {
    const biSession = window.wixBiSession || {};
    performGetRequest(BI_EVENTS_URL, {
      src: BI_EVENT_SRC,
      msid: this.getSiteId(),
      vsi: biSession.viewerSessionId,
      vid: biSession.visitorId,
      ...params,
    });
  }

  getSiteId() {
    return document
      .querySelector('meta[http-equiv="X-Wix-Meta-Site-Id"]')
      .getAttribute('content');
  }
}

export default BiEventsLogger;
