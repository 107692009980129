import { SUPPORTED_USER_AGENTS } from './config';

export function isMobile(userAgent) {
  return SUPPORTED_USER_AGENTS.test(userAgent);
}

export function localStorageRead(key) {
  const record = localStorage.getItem(key);
  if (!record) {
    return false;
  }
  const { value, timestamp } = JSON.parse(record);

  return Date.now() < timestamp && value;
}

export function localStorageWrite(key, value, expiration) {
  localStorage.setItem(
    key,
    JSON.stringify({
      value,
      timestamp: Date.now() + expiration,
    }),
  );
}

export function createElementFromString(str) {
  const el = document.createElement('div');
  el.innerHTML = str.trim();
  return el.firstChild;
}

export function prependElementToBody(element) {
  document.body.insertBefore(element, document.body.firstChild);
}

export function removeElement(element) {
  element.parentNode.removeChild(element);
}

export function serializeUrlParams(params) {
  let str = '';
  for (const key in params) {
    const param = params[key];
    // ignore undefined params
    if (param === undefined) {
      continue;
    }
    if (str !== '') {
      str += '&';
    }
    str += key + '=' + encodeURIComponent(param);
  }
  return str;
}

// Simple GET request when the response is not interesting
export function performGetRequest(url, params) {
  const urlParams = serializeUrlParams(params);
  const element = new Image();
  element.src = `${url}?${urlParams}`;
  // return created element, for easy testing
  return element;
}

export function isElementFixed(element) {
  const elementStyle = getElementStyle(element);
  return elementStyle.position === 'fixed';
}

export function getElementStyle(element) {
  // if browser supports getComputed style - use it. otherwise read direct style only
  return (
    (window.getComputedStyle && window.getComputedStyle(element)) ||
    element.style
  );
}
