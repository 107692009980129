exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes v-0hI{0%{top:-60px}to{top:0}}@keyframes v-0hI{0%{top:-60px}to{top:0}}.omDao{position:fixed;top:0;width:100%;height:60px;z-index:9999;display:-webkit-box;display:-ms-flexbox;display:flex;font-family:Helvetica,Arial,sans-serif;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#f1f1f1;-webkit-box-shadow:0 2px 10px 1px rgba(0,0,0,.15);box-shadow:0 2px 10px 1px rgba(0,0,0,.15);-webkit-animation:v-0hI .2s linear;animation:v-0hI .2s linear}._3AStw{position:fixed!important;top:0;-webkit-transform:translateY(60px);transform:translateY(60px)}._3AStw>div{height:calc(100vh - 60px);overflow-y:auto}._3XuKF{-webkit-transition:-webkit-transform .2s linear;transition:-webkit-transform .2s linear;transition:transform .2s linear;transition:transform .2s linear,-webkit-transform .2s linear}.EtFCY{-webkit-transition:top .2s linear;transition:top .2s linear;top:-60px}._3eHSU{-webkit-box-flex:1;-ms-flex:1;flex:1;font-size:12px;line-height:18px;text-align:left;color:#000}._1tN54{padding:14px;cursor:pointer;background:transparent;border:0}._1tN54 svg{width:13px;height:13px}._3Ct8b{display:block;margin-right:14px;font-size:13px;width:89px;height:28px;line-height:28px;border:2px solid #000;border-radius:16px;text-decoration:none;color:#000;background:#fdfdfd;text-align:center;font-weight:bolder}", ""]);

// exports
exports.locals = {
	"animationDuration": "200ms",
	"bannerHeight": "60px",
	"banner": "omDao",
	"slide-down": "v-0hI",
	"slideDown": "v-0hI",
	"site-container-push": "_3AStw",
	"siteContainerPush": "_3AStw",
	"site-container-push-animation": "_3XuKF",
	"siteContainerPushAnimation": "_3XuKF",
	"banner-remove": "EtFCY",
	"bannerRemove": "EtFCY",
	"text": "_3eHSU",
	"close-button": "_1tN54",
	"closeButton": "_1tN54",
	"app-link": "_3Ct8b",
	"appLink": "_3Ct8b"
};